import React, { useState } from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, Stats } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { roadmapsExtraRequests } from '@src/api/roadmaps'
import { FETCH_JIRA_ROADMAP } from '@src/constants/externalLinks'
import {
  roadmapCompanyOrgUnitColumn,
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
} from '@src/constants/columns/roadmap'
import { ownerNameColumn } from '@src/constants/columns/employee'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { getLocalDateTime } from '@src/utils/roadmap'
import { Color, MoreBar, TableWidget, Text } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import { TableNames } from '@src/constants/table'
import { useGetRoadmapSettings } from '@src/api/settings'
import { Filter } from '@revolut/icons'
import { selectorKeys } from '@src/constants/api'
import ButtonFilter from '@components/ButtonFilters/ButtonFilter'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { useShowRoadmapDetails, useRoadmapContext } from '@src/features/Roadmaps'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { foreignGoalColumn } from '@src/constants/columns/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useQuery } from '@src/utils/queryParamsHooks'
import { FilterSelectType } from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'

interface FilterOption {
  name: string
  id: number | string
}

const ROW: RowInterface<RoadmapInterface> = {
  cells: [
    {
      ...roadmapGenericNameColumn,
    },
    {
      ...roadmapCompanyOrgUnitColumn,
      width: 170,
    },
    {
      ...foreignGoalColumn,
      width: 100,
    },
    {
      ...roadmapProgressColumn,
      width: 170,
    },
    {
      ...roadmapStatusColumn,
      width: 100,
    },
    {
      ...roadmapStartDate,
      width: 130,
    },
    {
      ...roadmapDueDate,
      width: 130,
    },
    {
      ...roadmapResolutionAtColumn,
      width: 150,
    },
    {
      ...ownerNameColumn,
      width: 160,
    },
    {
      ...roadmapEpicUrlColumn,
      width: 120,
    },
  ],
}

export const CompanyRoadmaps = ({
  initialCycle,
}: {
  initialCycle: ReviewCyclesInterface
}) => {
  const { query } = useQuery()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const ctx = useRoadmapContext()
  const jiraEnabled = roadmapSettings?.jira_epics_enabled

  const [value, setValue] = useState<FilterOption[]>([])
  const [expandAllChildren, setExpandAllChildren] = useState(false)
  const currentCycleId = query.review_cycle__id || String(initialCycle.id)
  const initialFilter = [
    {
      filters: [{ name: currentCycleId, id: currentCycleId }],
      columnName: 'review_cycle__id',
      nonResettable: true,
    },
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'is_company',
      nonResettable: true,
    },
  ]

  const table = useTable<RoadmapInterface, Stats>(roadmapsExtraRequests, initialFilter)

  const getFilters = (filterOptions: FilterOption[]) => {
    const additionalFilters = filterOptions.map(filter => ({
      id: filter.id,
      name: filter.name,
    }))

    return additionalFilters.length
      ? [
          {
            filters: additionalFilters,
            columnName: 'status',
            nonResettable: true,
          },
        ]
      : []
  }

  const refetchFilterChildren = (filterOptions: FilterOption[]) => {
    const filterBy = getFilters(filterOptions)
    table.data.forEach((parent, ind) => {
      table.fetchChildren([ind], parent.id, filterBy)
    })
  }

  const { renderSidebar, showRoadmapDetails } = useShowRoadmapDetails({
    changelogState: false,
    jiraEnabled: !!jiraEnabled,
  })

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <CycleFilter
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__id"
            filter={table.filterBy}
            selector={selectorKeys.review_cycles}
            type={CycleFilterType.NewUI}
            filterInputType={FilterSelectType.SingleSelect}
          />
          <OverallProgress value={table?.stats?.avg_done} />
        </TableWidget.Info>
        <TableWidget.Actions>
          {roadmapSettings?.jira_epics_enabled && (
            <MoreBar>
              <MoreInfoButton href={FETCH_JIRA_ROADMAP} />
              <SwitchButton
                checked={expandAllChildren}
                onClick={() => {
                  setExpandAllChildren(!expandAllChildren)
                }}
              >
                Show all sub-tasks
              </SwitchButton>
            </MoreBar>
          )}
        </TableWidget.Actions>
        <TableWidget.Filters>
          <ButtonFilter
            title="Sub-task status"
            onChange={selection => {
              setValue(selection)
              refetchFilterChildren(selection)
            }}
            selector={selectorKeys.roadmap_child_status}
            value={value.map(el => ({ ...el, value: el }))}
            type="MultiSelect"
            useIcon={Filter}
          />
        </TableWidget.Filters>
        <TableWidget.Table>
          {!!table?.stats?.refresh_date_time && (
            <Text mb="s-12" mt="s-8" use="div">
              Progress last updated:{' '}
              <Text color={Color.GREY_TONE_50}>
                {getLocalDateTime(table?.stats?.refresh_date_time)}
              </Text>
            </Text>
          )}
          <AdjustableTable<RoadmapInterface>
            name={TableNames.CompanyRoadmap}
            useWindowScroll
            row={ROW}
            onRowClick={showRoadmapDetails}
            {...table}
            dataType={ctx.dataType}
            pendingDataType={ctx.isLoading}
            noDataMessage={ctx.noDataMessage}
            expandableType="chevron"
            fetchChildren={(parentIndexes, id) =>
              table.fetchChildren(parentIndexes, id, getFilters(value))
            }
            childrenAlwaysOpen={expandAllChildren}
          />
        </TableWidget.Table>
      </TableWidget>
      {renderSidebar({ canEdit: false })}
    </>
  )
}
