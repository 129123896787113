import React from 'react'
import { RoadMapTablesProps, useRenderRoadmapTables } from './table'
import { Box, Flex, TableWidget } from '@revolut/ui-kit'
import {
  CycleFilter,
  CycleFilterType,
} from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { FilterSelectType } from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { AddRoadmapMoreBarAction } from '@src/pages/Forms/RoadmapForm/common/AddRoadmapMoreBarAction'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { RoadmapActionsBar, useRoadmapRefresh } from '.'
import RoadmapLockedChangesBanner from '../Banners/RoadmapLockedChangesBanner'
import { RoadmapSettingsInterface } from '@src/interfaces/settings'
import MoreInfoButton from '@src/components/MoreInfoButton/MoreInfoButton'
import { FETCH_JIRA_ROADMAP } from '@src/constants/externalLinks'
import { useGetRoadmapSettings } from '@src/api/settings'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { TableNames } from '@src/constants/table'
import { PerformanceLayoutCycleFilter } from '@components/Inputs/Filters/FilterSelect/CycleFilter/PerformanceLayoutCycleFilter'
import { useIsNewOrgLayout } from '@src/pages/Team/helpers'
import { OverallProgress } from '../Goals/components/OverallProgress'
import RoadmapDeadlineBanner from '../Banners/RoadmapDeadlineBanner'

export interface RoadmapTabProps extends RoadMapTablesProps {
  allowRefresh: boolean
  addRoadmapProps: {
    entityType: EntityTypes
    id: number
    backUrl: string
    name: string
    tags?: string[]
  }
  triggerRefresh: (cycleId?: string | number) => Promise<void>
  tableInfoMessage?: React.ReactNode
  roadmapSettings?: RoadmapSettingsInterface
  showTableInfo: boolean
  newLayoutNavigation?: React.ReactElement
}

export const RoadmapTab = (props: RoadmapTabProps) => {
  const {
    addRoadmapProps,
    triggerRefresh,
    tableInfoMessage,
    roadmapSettings,
    allowRefresh,
    showTableInfo,
    newLayoutNavigation,
    ...tableOuterProps
  } = props
  const { allowEdit, reviewCycle } = tableOuterProps
  const { table, changelogSwitcher, changelogState, renderTable } =
    useRenderRoadmapTables(tableOuterProps)
  const isNewLayout = useIsNewLayout()
  const isNewOrgLayout = useIsNewOrgLayout()

  const { renderRefreshNote, refreshButton, pendingTaskId } = useRoadmapRefresh({
    epics: table.data.map(r => r.key),
    onAfterRefresh: () => table.refresh(),
  })
  const { data: settings } = useGetRoadmapSettings()
  const moreBarPending = !roadmapSettings || !props.reviewCycle

  return (
    <TableWidget>
      <TableWidget.Info>
        <Flex minWidth="fit-content">
          {isNewLayout && addRoadmapProps.entityType === EntityTypes.employee ? (
            <PerformanceLayoutCycleFilter
              onFilterChange={table.onFilterChange}
              columnName="review_cycle__id"
              selector={selectorKeys.review_cycles}
              filter={table.filterBy}
            />
          ) : (
            <CycleFilter
              type={CycleFilterType.NewUI}
              onFilterChange={table.onFilterChange}
              columnName="review_cycle__id"
              filter={table.filterBy}
              selector={selectorKeys.review_cycles}
              filterInputType={FilterSelectType.SingleSelect}
            />
          )}
        </Flex>
        <OverallProgress value={table?.stats?.avg_done} />

        <RoadmapDeadlineBanner reviewCycle={reviewCycle || null} />
      </TableWidget.Info>
      {!!newLayoutNavigation &&
        ((isNewLayout && props.tableProps.name === TableNames.EmployeeRoadmap) ||
          (isNewOrgLayout && props.tableProps.name === TableNames.DepartmentRoadmap)) && (
          <TableWidget.Filters>
            <Flex width="100%" justifyContent="flex-end">
              {newLayoutNavigation}
            </Flex>
          </TableWidget.Filters>
        )}
      <TableWidget.Actions>
        <RoadmapActionsBar pending={moreBarPending}>
          {allowEdit && (
            <AddRoadmapMoreBarAction
              {...addRoadmapProps}
              onAfterSubmit={() => table.refresh()}
              reviewCycle={reviewCycle || null}
            />
          )}
          {changelogSwitcher}
          {allowRefresh && refreshButton}
          {settings?.jira_epics_enabled ? (
            <MoreInfoButton href={FETCH_JIRA_ROADMAP} />
          ) : null}
        </RoadmapActionsBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <RoadmapLockedChangesBanner
          hidden={!changelogState.active}
          pb="s-8"
          reviewCycle={reviewCycle || null}
        />
        <Flex style={{ position: 'relative' }} flex="1 0">
          {renderTable({
            loading: !!pendingTaskId,
          })}
        </Flex>

        {allowRefresh && (
          <Box mt="s-12">
            {renderRefreshNote({ lastUpdated: table?.stats?.refresh_date_time })}
          </Box>
        )}
      </TableWidget.Table>
    </TableWidget>
  )
}
