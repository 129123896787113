import React from 'react'
import { connect } from 'lape'

import { ROUTES } from '@src/constants/routes'
import { Route, Switch, useParams } from 'react-router-dom'

import Preview from './Preview'
import General from './General'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import Form from '@src/features/Form/Form'
import { pathToUrl } from '@src/utils/router'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageWrapper } from '@components/Page/Page'
import { Box, Flex } from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { CompanyGoalInterface } from '@src/interfaces/companyGoals'
import { companyGoalsRequestsNew } from '@src/api/companyGoals'

const getTabs = (params: { id: string }) => [
  {
    title: 'Preview',
    path: ROUTES.FORMS.COMPANY_GOALS.PREVIEW,
    to: pathToUrl(ROUTES.FORMS.COMPANY_GOALS.PREVIEW, params),
    component: Preview,
  },
  {
    title: 'Edit',
    path: ROUTES.FORMS.COMPANY_GOALS.GENERAL,
    to: pathToUrl(ROUTES.FORMS.COMPANY_GOALS.GENERAL, params),
    component: General,
  },
]

const CompanyGoals = connect(() => {
  const params = useParams<{ id: string }>()
  const { values } = useLapeContext<CompanyGoalInterface>()
  const tabs = getTabs(params)

  return (
    <PageWrapper>
      <PageHeader
        title={(values.id && values.name) || 'New Company Goal'}
        backUrl={ROUTES.FORMS.COMPANY.GOALS.GENERAL}
      >
        {values.id && (
          <Box mb="s-8">
            <TabBarNavigation tabs={tabs} />
          </Box>
        )}
      </PageHeader>

      <Flex flexDirection="column" width="100%" flex={1}>
        <Switch>
          {tabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component data={values} />
            </Route>
          ))}
        </Switch>
      </Flex>
    </PageWrapper>
  )
})

const CompanyGoalsForm = () => (
  <Form api={companyGoalsRequestsNew} validator={{}}>
    <CompanyGoals />
  </Form>
)

export default connect(CompanyGoalsForm)
