import React from 'react'
import isUndefined from 'lodash/isUndefined'
import { TeamInterface } from '@src/interfaces/teams'
import { refreshRoadmap } from '@src/api/roadmaps'
import { TableNames } from '@src/constants/table'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import { EntityTypes } from '@src/constants/api'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RoadmapTab } from '@src/features/Roadmaps/RoadmapTab'
import { useGetRoadmapSettings } from '@src/api/settings'

interface StructureProps {
  data: TeamInterface
}

export const getTeamRoadmapFilters = ({ id }: TeamInterface) => [
  {
    filters: [{ name: id.toString(), id }],
    columnName: 'team__id',
    nonResettable: true,
  },
  {
    filters: [{ name: `0`, id: 0 }],
    columnName: 'review_cycle__offset',
    nonResettable: true,
  },
]

export const Roadmap = ({ data }: StructureProps) => {
  const { query } = useQuery()

  const cycleOffset = query.review_cycle__offset || 0
  const { data: reviewCycle } = useGetReviewCycleByOffset(cycleOffset)
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const addRoadmapProps = {
    entityType: EntityTypes.team,
    id: data.id,
    backUrl: pathToUrl(ROUTES.FORMS.TEAM.ROADMAP, { id: data.id }),
    name: data.name,
    tags: data.jira_projects || [],
  }

  const allowEdit = !!data.field_options.permissions?.includes(
    PermissionTypes.CanCreateRoadmaps,
  )

  return (
    <>
      <RoadmapTab
        showTableInfo={allowEdit}
        allowRefresh
        defaultFilters={getTeamRoadmapFilters(data)}
        reviewCycle={reviewCycle}
        allowEdit={allowEdit}
        addRoadmapProps={addRoadmapProps}
        roadmapSettings={roadmapSettings}
        triggerRefresh={async () => {
          await refreshRoadmap(
            `TEAM-${data.id}`,
            !isUndefined(reviewCycle) ? { id: reviewCycle.id as number } : undefined,
          )
        }}
        tableProps={{ name: TableNames.TeamRoadmap }}
      />
    </>
  )
}

export default Roadmap
